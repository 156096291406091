
import { Component, Vue, Watch } from '@/lib/decorator';
import TitlePage from '@/components/Title.vue';
import AppHeaderBtnAdd from '@/layouts/partials/AppHeaderBtnAdd.vue';
import IconFont from '@/components/icons/IconFont.vue';
import ArrowReturn from '@/components/icons/ArrowReturn.vue';

import CustomersModule from '@/store/customers';
import AppTable from '@/components/ui/table/Table.vue';
import TextPhone from '@/components/table-items/TextPhone.vue';
import AppTableColumnContext from '@/components/ui/table/TableColumnContext.vue';
import TextNumber from '@/components/table-items/TextNumber.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import { EventChangeSortHeader, TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import { getClientPhone } from '@/api/client';
import { makePhoneCall } from '@/lib/util/phone';
import userModule from '@/store/user';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { GuiLoader } from '@library/gigant_ui';
import FormInput from '@/components/form/Input/index.vue';
import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';

@Component({
  components: {
    AppHeaderMain,
    GuiLoader,
    AppRow,
    AppCol,
    TextNumber,
    AppTableColumnContext,
    TextPhone,
    AppTable,
    TitlePage,
    AppHeaderBtnAdd,
    IconFont,
    ArrowReturn,
    FormSimpleSwitch,
    FormInput,
  },
})
export default class CustomersMain extends Vue {
  headers: TableHeaderInterface[] = [];
  hiddenHeaders: TableHeaderInterface[] = [];
  customerName = '';

  get canEditCustomer(): boolean {
    return userModule.userHasPermission('CAN_EDIT_CLIENT');
  }

  get onlyActive(): boolean {
    return CustomersModule.onlyActive;
  }

  get isSettingColumn(): boolean {
    return CustomersModule.isSettingColumn;
  }

  get settings(): PageInterface {
    return CustomersModule.pageSettings;
  }

  get filterSettings() {
    return CustomersModule.filter.filterSettings;
  }

  async updateList() {
    await CustomersModule.setPageToBegin();
    await CustomersModule.filter.updateFilter();
    await CustomersModule.getList();
  }

  async resetFilters() {
    await CustomersModule.setPageToBegin();
    await CustomersModule.filter.resetFilter();
    await CustomersModule.getList();
  }

  @Watch('settings.table.hiddenTitles')
  getHiddenTitles(hiddenTitles: TableHeaderInterface[]): void {
    this.hiddenHeaders = [...hiddenTitles];
  }

  @Watch('settings.table.titles')
  getTitles(titles: TableHeaderInterface[]): void {
    this.headers = [...titles];
  }

  toAdd(): void {
    this.$router.push({ name: 'customer_add' });
  }

  selectAmount(value: string): void {
    CustomersModule.updatePageAmountItems(value);
  }

  toSettings(): void {
    CustomersModule.updateIsSettingsColumn(true);
  }

  closeSettings(): void {
    CustomersModule.updateIsSettingsColumn(false);
  }

  changeVisibleHeaders(e: EventChangeSortHeader): void {
    const tmphiddenTitles = [...this.hiddenHeaders];
    const tmpTitles = e.headers;
    let element;
    switch (e.changed.type) {
      case 'update':
        element = tmpTitles[e.changed.oldIndex];
        tmpTitles.splice(e.changed.oldIndex, 1);
        tmpTitles.splice(e.changed.newIndex, 0, element);
        this.headers = tmpTitles;

        return;
      case 'add':
        element = tmphiddenTitles[e.changed.oldIndex];
        tmphiddenTitles.splice(e.changed.oldIndex, 1);
        tmpTitles.splice(e.changed.newIndex, 0, element);
        this.hiddenHeaders = tmphiddenTitles;
        this.headers = tmpTitles;

        return;
      case 'remove':
        element = tmpTitles[e.changed.oldIndex];
        tmpTitles.splice(e.changed.oldIndex, 1);
        tmphiddenTitles.splice(e.changed.newIndex, 0, element as never);
        this.hiddenHeaders = tmphiddenTitles;
        this.headers = tmpTitles;

        return;
    }
  }

  startDrag(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.addEventListener('dragover', this.dragenter, { capture: true });
    element.addEventListener('dragleave', this.dragleave, { capture: false });
  }

  endDrag(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.removeEventListener('dragover', this.dragenter);
    element.removeEventListener('dragleave', this.dragleave);
  }

  dragenter(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.classList.add('target-hover');
  }

  dragleave(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.classList.remove('target-hover');
  }

  saveColumnSettings(): void {
    CustomersModule.saveSettingsToStore({
      hidden: this.hiddenHeaders,
      visible: this.headers,
    });
  }

  cancelColumnSettings(): void {
    this.closeSettings();

    this.hiddenHeaders =
      CustomersModule.hiddenTitles && CustomersModule.hiddenTitles.length
        ? [...CustomersModule.hiddenTitles]
        : 'hiddenTitles' in this.settings.table && Array.isArray(this.settings.table.hiddenTitles)
        ? [...this.settings.table.hiddenTitles]
        : [];

    this.headers =
      CustomersModule.orderTitles && CustomersModule.orderTitles.length
        ? [...CustomersModule.orderTitles]
        : 'titles' in this.settings.table && Array.isArray(this.settings.table.titles)
        ? [...this.settings.table.titles]
        : [];
  }

  onlyActiveUpdated(value: boolean): void {
    this.$store.dispatch(CustomersModule.pageSettings.actionPagination as string, 1);
    CustomersModule.updateOnlyActive(value);
  }

  async makeCall(customerId: string): Promise<void> {
    try {
      const phone = await getClientPhone(customerId);
      makePhoneCall(phone);
    } catch (error) {
      console.error(error);
    }
  }

  mounted(): void {
    CustomersModule.initList();
  }
}
