import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb8eda12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cp-flex--inline cp-flex--align-center cp-line-height-none cp-padding__r--40" }
const _hoisted_2 = { class: "cp-flex align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_main = _resolveComponent("app-header-main")!
  const _component_form_simple_switch = _resolveComponent("form-simple-switch")!
  const _component_app_header_btn_add = _resolveComponent("app-header-btn-add")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_arrow_return = _resolveComponent("arrow-return")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_app_table_column_context = _resolveComponent("app-table-column-context")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_text_phone = _resolveComponent("text-phone")!
  const _component_text_number = _resolveComponent("text-number")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_app_row = _resolveComponent("app-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, {
        grow: 0,
        class: "cp-admin-title__wrapper cp-flex--align-center cp-padding__lr-10 cp-margin__t--no"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_app_header_main, {
            title: _ctx.settings.title,
            "filter-settings": _ctx.filterSettings,
            "is-to-add-hidden": true,
            onResetFilters: _ctx.resetFilters,
            onUpdateList: _ctx.updateList,
            "is-center": true
          }, null, 8, ["title", "filter-settings", "onResetFilters", "onUpdateList"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_form_simple_switch, {
              "model-value": _ctx.onlyActive,
              horizontal: true,
              label: "Только активные",
              onUpdate: _ctx.onlyActiveUpdated
            }, null, 8, ["model-value", "onUpdate"])
          ]),
          (!_ctx.isSettingColumn)
            ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
                (_ctx.canEditCustomer)
                  ? _withDirectives((_openBlock(), _createBlock(_component_app_header_btn_add, {
                      key: 0,
                      class: "cp-admin-control__wrapper",
                      onClick: _ctx.toAdd
                    }, null, 8, ["onClick"])), [
                      [_directive_tooltip, 'Добавить клиента']
                    ])
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: "cp-admin-control__wrapper cp-icon-primary-2 cp-cursor-pointer cp-flex",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toSettings && _ctx.toSettings(...args)))
                }, [
                  _createVNode(_component_icon_font, {
                    size: "18",
                    icon: "settings"
                  })
                ])
              ], true)
            : _createCommentVNode("", true),
          (_ctx.isSettingColumn)
            ? _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
                _createElementVNode("div", {
                  class: "cp-arrow-return",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeSettings && _ctx.closeSettings(...args)))
                }, [
                  _createVNode(_component_arrow_return, { color: "#9B9BC7" })
                ])
              ], true)
            : _createCommentVNode("", true)
        ]),
        _: 3
      }),
      (Object.keys(_ctx.headers).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (Object.values(_ctx.headers).length > 0)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small grow-1",
            headers: _ctx.headers,
            items: _ctx.settings.table.rows,
            "is-edit": _ctx.isSettingColumn,
            page: _ctx.settings.table.pagination.currentPage,
            "total-pages": _ctx.settings.table.pagination.totalPages,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            "total-items": _ctx.settings.table.totalItems,
            "start-drag": _ctx.startDrag,
            "end-drag": _ctx.endDrag,
            onChangePage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount,
            onSortHeaders: _ctx.changeVisibleHeaders
          }, {
            top: _withCtx(() => [
              (_ctx.isSettingColumn)
                ? (_openBlock(), _createBlock(_component_app_table_column_context, {
                    key: 0,
                    class: "width-full cp-padding__bsx",
                    ref: "target",
                    headers: _ctx.hiddenHeaders,
                    onSave: _ctx.saveColumnSettings,
                    onCancel: _ctx.cancelColumnSettings
                  }, null, 8, ["headers", "onSave", "onCancel"]))
                : _createCommentVNode("", true)
            ]),
            "item-name": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_router_link, {
                  class: "app-table-link",
                  to: { name: 'customer_edit', params: { customerId: item.id } }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (item.isTest)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-phone": _withCtx(({ value, item }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_phone, {
                    key: 0,
                    value: value,
                    onPhoneClick: ($event: any) => (_ctx.makeCall(item.id))
                  }, null, 8, ["value", "onPhoneClick"]))
                : _createCommentVNode("", true)
            ]),
            "item-markets": _withCtx(({ value }) => [
              _createVNode(_component_text_number, { value: value }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["headers", "items", "is-edit", "page", "total-pages", "page-items", "total-items", "start-drag", "end-drag", "onSelectAmount", "onSortHeaders"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}